// @import "../components/header/header-style.less";
// @import "../components/common/common-commonents.less";
@import '../../node_modules/antd/dist/antd.css';
@import './fonts.scss';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -1px;
}

body {
  font-size: 12px;
}

.mission-card {
  overflow: hidden;
}

input:not([type='range']) {
  line-height: 0 !important;
}

/*.MuiAutocomplete-popper {
  position: absolute;
  top: 100px !important;
}*/

.float-right-btn {
  float: right;
  margin-right: 17em;
  margin-top: 30px;
}

.add-btn-text {
  letter-spacing: 0;
}

::selection {
  background: #ef7d06;
}

.MuiFormGroup-root.MuiFormGroup-row {
  width: 100%;
}

.MuiFormGroup-row {
  width: 50%;
}

input {
  color: #ef7d06;
}

.text-area textarea:focus {
  padding: 0 8px;
  --antd-wave-shadow-color: #ef7d06;
  border: 1.5px solid #ef7d06 !important;
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #040200;
  margin-bottom: 25px;
}

.ArrayContextSearch {
  margin-top: 20px;
}

.testSharedStyle {
  font-style: italic;
  font-weight: bold;
  color: #712115;
  size: 46px;
}

.flexMargin {
  display: flex;
  > div {
    margin-right: 5px;
  }
}

.flex {
  display: flex;

  .row {
    flex-direction: row;
  }

  .column {
    flex-direction: column;
  }

  .space-between {
    justify-content: space-between;
  }

  .flex-end {
    justify-content: flex-end;
  }
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.center {
  justify-content: center;
}

.header-center {
  align-items: center;
  display: flex;
  justify-content: left;
  height: 75px;
}

.width100 {
  width: 100%;
}

.width75 {
  width: 75%;
}

.width25 {
  width: 25%;
}

.padding15 {
  padding: 15px 15px 15px 15px;
}

.main-app-content-class {
  margin: 0 11% 0 13%;
}

.mission-card,
.card-custom-subtitle {
  color: black;
  font-weight: 700;
  font-size: 18px;
}

.add-btn-text,
span.add-btn-text a {
  font-weight: 600;
  font-size: 14px;
  color: #bcbcbc;
  text-transform: none;
}

.add-btn-plus {
  font-weight: 500;
  font-size: 16px;
  color: #bcbcbc;
  margin-right: 10px;
}

.add-btn-row {
  font-weight: 500;
  font-size: 16px;
  color: #bcbcbc;
  margin-left: 10px;
}

.add-btn {
  border: 1px solid #bcbcbc !important;
}

.add-btn:hover {
  border-color: #ef7d06 !important;
}

.color-orange {
  color: #ef7d06 !important;
}

.add-btn:hover .MuiButton-label .add-btn-text {
  color: #ef7d06;
}

.add-btn-text:hover,
#user-registration-header button .add-btn-text:hover,
span.add-btn-text:hover a,
span.add-btn-text a:hover {
  color: #ef7d06 !important;
}

.add-btn-plus:hover {
  color: #ef7d06 !important;
}

.div-btn-header {
  margin-top: 2%;
  margin-bottom: 7%;
}

.MuiButton-root:hover {
  background-color: transparent !important;
}

.breadcrumbs-header {
  color: #f5b169;
  font-size: 18px;
}

.breadcrumbs-header a:active {
  color: #f5b169 !important;
}

.breadcrumbs-header a {
  color: #f5b169 !important;
  font-weight: 700;
  font-size: 16px;
}

.user-name-login {
  color: #ef7d06;
  font-weight: 700;
  font-size: 18px;
  margin-right: 15px;
}

.logo-container:hover {
  cursor: pointer;
}

.card-custom-role {
  text-transform: lowercase;
  font-size: 13px;
}

.card-user-icon {
  margin-right: 10px;
}

.records-subtitle {
  text-transform: lowercase;
}

.records-custom-card {
  display: inline-block;
}

.record-card-description {
  font-weight: 700;
}

.record-card {
  color: black;
}

.card-title {
  font-weight: 700;
  color: #575756;
  font-size: 16px;
}

.h2-text {
  font-weight: 700;
}

.div-header {
  margin-top: 2%;
  margin-bottom: 4%;
}

.user-role-select-div {
  margin-top: 2%;
  color: black;
  font-size: 14px;
}

.btn-form-footer {
  margin-top: 4%;
  margin-left: 20%;
}

.username-edit-text {
  color: #ef7d06;
  font-weight: 700;
}

.action-form {
  margin: 10px 40px 0 0;
  color: #bcbcbc;
  text-transform: uppercase;
  font-size: 9px;
}

.action-form:hover {
  color: #ef7d06;
  cursor: pointer;
}

.settings-row {
  margin-top: 20px;
}

.divider-settings {
  margin-bottom: 7%;
}

.setting-btn {
  padding-top: 20px !important;
  padding-bottom: 0px !important;
}

.setting-url {
  margin-top: 6px;
}

.btn-settings-url {
  color: #bcbcbc;
  text-transform: none;
}

.current-url {
  color: #ef7d06;
}

.formLabel {
  font-size: 1em;
  margin-right: 7px;
}

.new-mission-btn,
.back-btn {
  text-align: right;
}

.checkbox-start {
  margin-right: 20px !important;
  margin-left: 2px !important;
}

.checkbox-start > span {
  font-size: 1em !important;
  font-weight: bold !important;
}

.div-btn-photo {
  margin-top: 5%;
  margin-bottom: 5%;
}

.btn-footer {
  align-self: flex-end;
}

.MuiFormLabel-root {
  color: #000000 !important;
}

#new-mission-name {
  color: #ef7d06 !important;
}

.user-image {
  max-width: 60px;
  max-height: 60px;
}

.header-form-image {
  margin-right: 20px;
}

.user-add-text {
  font-size: 19px;
}

.user-add-sub-text {
  font-size: 13px;
}

.role-user {
  text-transform: lowercase;
}

.user-info {
  padding-top: 2px;
}

.user-image-list {
  margin-right: 10px;
}

.login-container p.MuiFormHelperText-root {
  position: relative;
  top: 0;
}

.record-button-container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.record-button-container-inner {
  width: 30%;
}

.record-button-container-button {
  display: flex;
  flex-direction: row-reverse;
}

.record-button-container .MuiDivider-middle {
  margin: 0 !important;
}

.ant-dropdown-menu {
  top: 13px;
  border-left: 1.5px solid #bebcc1;
  height: auto !important;
  border-radius: 0;
  padding: 0;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: #bebcc1;
  font-weight: 600;
  padding: 2px 8px;
}

.ant-dropdown-menu-item:hover {
  color: #6c6057;
}

/*** TT changes 2019-12-16 **/
.main-app-class {
  padding: 1.5em;
}

.h2-login-title,
.h2-login-info {
  font-size: 1.4em;
  letter-spacing: -1px;
  line-height: 1.2;
}

.MuiFormControl-marginNormal {
  width: 100%;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.MuiInput-underline:before {
  border-bottom: 1.5px solid #616160 !important;
}

.MuiInputLabel-root {
  color: #616160 !important;
}

.MuiFormHelperText-root {
  margin-top: 1px !important;
  font-size: 0.62em !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
}

.MuiButton-root {
  border-radius: 0 !important;
  line-height: 1 !important;
}

.login-button,
.btn-create {
  padding: 7px 50px 7px 19px !important;
}

.new-mission-btn {
  padding: 7px 50px 7px 19px !important;
}

.back-btn {
  padding: 7px 16px 7px 50px !important;
  background: url('../../public/pictures/strzalka_02_left.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position-x: 0%;
  &:hover {
    background: url('../../public/pictures/strzalka_02_left_orange.png');
  }
}

.login-button,
.new-mission-btn,
.btn-create {
  background: url('../../public/pictures/strzalka_02.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position-x: 100%;
}

.login-button:hover,
.new-mission-btn:hover,
.btn-create:hover {
  background: url('../../public/pictures/strzalka_02_orange.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position-x: 100%;
}

.trash-btn {
  cursor: pointer;
  svg {
    transition: all 0.3s;
  }
  &:hover {
    svg {
      transition: all 0.3s;
      color: darken(#d1d1d1, 50) !important;
    }
  }
}

.edit-icon {
  position: absolute !important;
  top: 0;
  right: 0;
  margin-top: -5px !important;
  cursor: pointer;
  svg {
    transition: all 0.3s;
  }
  &:hover {
    svg {
      transition: all 0.3s;
      color: darken(#d1d1d1, 50) !important;
    }
  }
}
.MuiButton-root span {
  font-size: 18px;
}

.add-button {
  background: url('../../public/pictures/plus_02.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position-x: 0;
  padding: 4px 2px 2px 30px !important;
}

.MuiButton-root:hover .add-button {
  background: url('../../public/pictures/plus_02_orange.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position-x: 0;
}

.card .action-item {
  margin: 0 10% 0 5px;
  color: #bcbcbc !important;
  font-size: 0.8em !important;
  font-weight: 500;
  letter-spacing: 0;
}

.card .action-item a {
  font-size: 10px !important;
  white-space: nowrap;
}

.ant-list-item {
  font-size: 12px;
}

.card .action-item:hover {
  cursor: pointer;
  color: #ef7d06 !important;
}

.card .card-custom-subtitle {
  color: #000;
}

.card:hover .mission-card,
.card:hover .card-custom-subtitle {
  cursor: pointer;
  color: #ef7d06;
}

.breadcrumbs-header {
  color: #bcbcbc !important;
  font-weight: 600;
}

.breadcrumbs-header span.ant-breadcrumb-link:hover {
  cursor: pointer;
  color: #ef7d06 !important;
}

.breadcrumbs-header span:last-child span.ant-breadcrumb-link {
  color: #f5b26b !important;
}

.ant-breadcrumb-link {
  display: inline-block;
}

.breadcrumbs-header span:last-child span.ant-breadcrumb-separator {
  display: none;
}

.ant-breadcrumb-separator {
  color: rgba(0, 0, 0, 0);
  background: url('../../public/pictures/strzalka_02.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  width: 25px;
  display: inline-block;
}

.SearchContainer {
  margin-right: 20px;
  width: 170px;
}

.SearchContainer label + .MuiInput-formControl {
  margin-top: 0;
  line-height: 0;
}

.label + .MuiInput-formControl {
  margin-top: 16px;
}

.SearchContainer label {
  font-size: 0.8em;
  color: #e3e3e3 !important;
  margin-top: -15px;
}

.SearchContainer .MuiIconButton-label svg {
  fill: #bcbcbc !important;
}

.SearchContainer .MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0);
}

.MuiAutocomplete-popupIndicatorOpen {
  transform: none !important;
}

/*.SearchContainer .MuiIconButton-label {
  background: url('web-version/public/strzalka_02.png');
  background-size: auto 80%;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  display: inline-block;
}*/

/*
.SearchContainer .MuiAutocomplete-clearIndicatorDirty .MuiIconButton-label {
  background: url('web-version/public/x_02.png');
  background-size: auto 80%;
  background-repeat: no-repeat;
  width: 20px;
}
*/

.SearchContainer .MuiInput-underline:before {
  border-bottom: 1.5px solid #828281 !important;
  width: 130px;
}

.SearchContainer .MuiInput-underline:after {
  width: 130px;
}

.SearchContainer .MuiFormControl-marginNormal {
  padding-right: 0;
}

.ant-list-grid .ant-col > .ant-list-item {
  width: 300px;
}

.formHeadline,
.formLabel {
  font-weight: 700 !important;
  color: #000;
  white-space: nowrap;
}

.body-record {
  margin-top: 10px;
  padding: 30px 17em 30px 30px;
}

.record-header {
  margin-top: 40px;
  font-size: 14px;
}

.record-fields {
  margin-top: 45px;
}

.record-context-field {
  margin-right: 15px;
}

.record-context-label {
  width: 100%;
  font-weight: normal;
}

.record-fields-small-main {
  margin-right: 15px;
}

.record-fields-small-text {
  width: 100%;
}

.record-fields-small-label {
  font-weight: normal;
}

.record-elevation {
  margin-top: 50px;
}

.record-elevation-dimensions {
  padding-top: 60px;
  align-items: baseline;
  background: url('../../public/pictures/dimensions.png');
  background-size: auto 136%;
  background-repeat: no-repeat;
  background-position-x: right;
}
.label-width-record {
  margin-right: 30px;
  margin-bottom: 16px;
}

.record-text-input-small {
  margin-right: 15px;
  width: 200px;
}

.record-text-input-big,
.remarks-big-input {
  width: 100%;
}

.label-width-record-original {
  width: 125px;
  font-weight: normal !important;
}

.label-width-record-big {
  width: 200px;
}

span.MuiTypography-body1 {
  font-weight: 700;
  color: #000;
}

.MuiCheckbox-root,
.MuiSvgIcon-root {
  border-radius: 0;
  color: #d1d1d1 !important;
}

.MuiCheckbox-colorPrimary.Mui-checked,
.Mui-checked .MuiCheckbox-root,
.Mui-checked .MuiSvgIcon-root {
  color: #ef7d06 !important;
}
.record-fields-label-recorded {
  width: 190px;
  font-weight: normal;
}

.photos-margin {
  margin-top: 20px;
}

.save-photo-btn {
  margin-top: 10px;
}

.delete-photo-btn {
  margin-top: 20px;
  margin-bottom: 20px;
}

.width-label-check {
  width: 185px;
}

.checkbox-group {
  margin-top: 0px;
}

.checkbox-group-item {
  margin-top: 0;
  white-space: nowrap;
}

.ArrayContextSearch .checkbox-group-item,
.ArrayContextSearchWithPin .checkbox-group-item {
  margin-top: 0;
}

.other-checkbox-label {
  margin: 4px 10px 0 0;
  letter-spacing: 0.00938em;
  line-height: 0.5;
}

.without-margin {
  margin: 0px 0px 0 0;
}

.text-special-checkbox {
  width: 100%;
  margin-left: 10px;
}

.style-special-checkbox {
  margin-top: 9px;
}

.last-record {
  margin-top: 60px;
  margin-bottom: 100px;
}

.radio-items {
  margin-right: 70px !important;
}

.label-find-record {
}

.second-field {
  margin-left: 19%;
}

.record-checkboxes {
  margin-left: 11%;
}

.find-checkbox {
  width: 110px;
  margin-left: 10%;
}

.date-checkbox {
  margin-left: 4%;
}

.find-checkbox-fragments {
  margin-right: 5%;
}

.additional-text-label {
  margin: 7px 0 0 2px;
}

.checkbox-feature-record {
  margin: 15px 0 0 30px;
}

.record-fields-very-small-text {
  width: 50px;
}

.deposition-column {
  margin-right: 15%;
}

.right-padding {
  padding-right: 18%;
}

.fifty-percent {
  width: 50%;
}

.fifteen-percent {
  width: 15%;
}

.thirty-percent {
  width: 33%;
}

.fourty-four-percent {
  width: 44%;
}

.twenty-five-percent {
  width: 25%;
}

.twenty-five-percent input {
  width: 100%;
}

.checkbox-group-item .MuiFormControlLabel-root,
.checkbox-group-item span.MuiTypography-body1 {
  width: 100%;
  margin-right: 0;
  margin-top: 10px;
}

input:not([type='range']) {
  line-height: 2;
}

.checkbox-group .fourty-four-percent:first-of-type {
  margin-right: 12%;
}
div.thirty-percent .MuiInputBase-input,
.MuiInputBase-input {
  padding: 0 0 1px !important;
}

div.thirty-percent input[type='text'],
div.thirty-percent .MuiInput-underline:before,
div.thirty-percent .Mui-focused .MuiInput-underline:before,
a,
area,
button,
[role='button'],
input:not([type='range']),
select,
summary,
textarea {
  text-align: right;
  font-size: 14px !important;
  margin-bottom: 2px;
  color: #ef7d06;
  font-weight: 400;
}

div.thirty-percent label + .MuiInput-formControl {
  margin-top: 0;
}

div.thirty-percent .MuiInputLabel-formControl {
  transform: translate(0, 0) scale(1);
  line-height: 0;
}

div.thirty-percent .MuiInputLabel-shrink {
  transform: translate(0, -20px) scale(0.75);
}

div.thirty-percent label {
  float: left;
  margin-right: 2px;
  line-height: 2.2;
}

.checkbox-group-item .MuiFormControl-root {
  margin-top: -6px;
}

.mt6 {
  margin-top: 6px;
}

.text-area .formLabel {
  width: 33%;
}

.text-area {
  height: auto;
}

.text-area textarea {
  border: 1.5px solid #616160 !important;
  border-radius: 0;
  width: 100%;
  padding: 0 8px;
}

.text-area textarea:disabled {
  background: #fff;
}

#user-edition .MuiFormHelperText-root {
  top: 40px;
}

::placeholder,
.MuiFormHelperText-root.Mui-error,
.MuiFormHelperText-root,
.MuiFormHelperText-root.MuiFormHelperText-filled {
  text-align: right !important;
  // position: absolute;
  // top: 26px;
  // right: 0;
  white-space: nowrap;
}

.MuiTypography-root {
  margin-bottom: 5px;
}

.record-fields-small-label-thin,
.full-width .record-fields-small-label-thin {
  font-weight: 300 !important;
  text-transform: lowercase;
  width: auto !important;
  white-space: nowrap;
}

.checkbox-start > span {
  font-weight: 700 !important;
  color: #000 !important;
  font-size: 1em;
  margin-right: 7px;
}

.full-width {
  width: 100%;
}

.full-width .formLabel {
  width: auto;
  white-space: nowrap;
}

.label-right .formLabel {
  text-align: right;
}

.mr20 {
  margin-right: 20px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mw130 {
  max-width: 130px;
}

.mw170 {
  max-width: 170px;
}

/********** photos **********/
.ant-empty {
  display: none;
}

/********* buttons *********/
.MuiButton-root {
  color: #ef7d06 !important;
}

.btn-edit {
  background: url('../../public/pictures/star.png');
  background-size: auto 75%;
  background-repeat: no-repeat;
  background-position: 5%;
}

.btn-edit:hover {
  background: url('../../public/pictures/star_orange.png');
  background-size: auto 75%;
  background-repeat: no-repeat;
  background-position: 5%;
}

.btn-edit span.add-btn-text,
.btn-add-item span.add-btn-text,
.btn-save span.add-btn-text,
.btn-delete span.add-btn-text {
  padding-left: 1.2em;
}

span.add-btn-text a {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 18px;
}

.btn-save,
.btn-add-item {
  background: url('../../public/pictures/plus_02.png');
  background-size: auto 75%;
  background-repeat: no-repeat;
  background-position: 5%;
}

body.edit-mode .btn-save.Mui-disabled:before,
.btn-create.Mui-disabled:before {
  content: 'some fields are invalid';
  position: absolute;
  margin-top: -26px;
  width: 138px;
  font-size: 8px;
  color: red;
}

body.edit-mode .btn-edit:before {
  content: 'edition mode on';
  position: absolute;
  left: -16px;
  margin-top: -26px;
  width: 138px;
  font-size: 8px;
  color: red;
}

.btn-create.Mui-disabled:before {
  right: -10px;
}

.btn-save:hover,
.btn-add-item:hover {
  background: url('../../public/pictures/plus_02_orange.png');
  background-size: auto 75%;
  background-repeat: no-repeat;
  background-position: 5%;
}

.btn-delete {
  background: url('../../public/pictures/x_02.png');
  background-size: auto 58%;
  background-repeat: no-repeat;
  background-position: 5%;
}

.btn-delete:hover {
  background: url('../../public/pictures/x_02_orange.png');
  background-size: auto 58%;
  background-repeat: no-repeat;
  background-position: 5%;
}

#user-registration .MuiFormControl-root,
#user-edition .MuiFormControl-root {
  width: 100%;
}

#user-registration,
#user-edition {
  width: 27%;
}

#user-registration .btn-form-footer,
#user-edition .btn-form-footer {
  float: right;
}

#user-edition .btn-form-footer {
  margin-top: 50px;
}

.MuiInputLabel-animated {
  text-transform: lowercase;
  margin-top: -6px;
}

.SearchContainer .MuiInputLabel-animated {
  text-transform: uppercase;
}

#user-registration div,
#user-edition div {
  margin-bottom: 5px;
}

#user-registration div div:first-child .MuiFormControl-root {
  margin-top: 20px;
  margin-bottom: 10px;
}

#user-registration div div:last-child .MuiFormControl-root {
  margin-bottom: 10px;
}

#user-registration-header .add-btn-text:hover {
  color: #bcbcbc !important;
}

/*************** PHOTOS **********/
.photos-list-container .ant-list-split .ant-list-item {
  max-width: 30%;
  float: left;
  margin-right: 10px;
}

.photos-list-container .ant-list-split .ant-list-item img {
  max-width: 100%;
  max-height: 160px;
}

.photos-body-action-container-class .action-item {
  font-size: 8px !important;
}

.photos-body-card-class {
  height: 250px;
}

.MuiPaper-rounded {
  border-radius: 0 !important;
}

.MuiPaper-rounded .MuiButton-label {
  font-size: 13px;
}

/****** pagination *******/
.ant-pagination-simple .ant-pagination-simple-pager input,
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border: none;
  color: #f5b26b !important;
  padding: 0;
  border-radius: 0;
  width: auto;
  border-bottom: 1px solid #bcbcbc;
}

.ant-pagination {
  color: #bcbcbc;
  float: right;
  font-size: 16px;
  line-height: 0;
}

.anticon.anticon-right svg,
.anticon.anticon-left svg {
  display: none;
}

.anticon.anticon-left {
  background: url('../../public/pictures/strzalka_02.png');
  width: 25px;
  height: 25px;
  background-size: auto 95%;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}

.anticon.anticon-right {
  background: url('../../public/pictures/strzalka_02.png');
  width: 25px;
  height: 25px;
  background-size: auto 95%;
  background-repeat: no-repeat;
}

.other-checkbox-label {
  font-size: 0.8rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

textarea {
  text-align: left !important;
}

.editable-cell {
  /*background: aliceblue;*/
  height: 14px;
  cursor: pointer;
}

.editable-cell .input {
  margin: -5px 0px -5px 0px;
  height: 24px;
}

.selectable-cell {
  /*background: aliceblue;*/
  height: 14px;
  cursor: pointer;
}

.selectable-cell .select {
  margin: -5px 0px -5px 0px;
  width: 100%;
}

.secure-denied {
  pointer-events: none;
  cursor: not-allowed !important;
}

.opacity {
  opacity: 0.3;
}

.secure-denied:hover::after {
  content: attr(data-title);
}

.pane {
  margin: 15px 15px 15px 15px;
  border: solid 1px #c6bcb8;
  border-radius: 5px;
  overflow: hidden;
}

.pane-title {
  background: rgba(48, 83, 117, 0.67);
  height: 46px;
  color: white;
  font-size: 14px;
}

.pane-body {
  background: white;
}

.padding-left {
  padding-left: 35px;
}
.header {
  .center {
    width: 79%;
    margin-left: 3%;
    border-bottom: 1.5px solid #bcbcbc;
  }

  .settpanel {
    width: 60px;
    margin-right: 45px;
    margin-top: 15px;

    .settings:hover {
      color: #712115;
    }

    > div {
      margin-right: 5px;
    }
  }

  .header-image {
    width: 100%;
  }

  .navigator {
    .back {
      margin-right: 5px;
      left: 5px;
    }

    .back:hover {
      color: #712115;
    }
  }
}

.ant-dropdown-menu {
  box-shadow: none;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: transparent;
  color: black;
  font-weight: 600;
}

.Mui-active {
  color: #ef7d06 !important;
}

.uploader {
  height: 130px !important;
  svg {
    path {
      fill: #ef7d06 !important;
    }
  }
  border-color: #ef7d06 !important;
}

.MuiTooltip-tooltip {
  font-size: 14px !important;
  width: 300px;
}

.full-page-wrapper {
  position: fixed;
  z-index: 100;
  background: white;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.warning-icon {
  color: #ff8c00 !important;
}
.word-break {
  word-break: break-word;
}

.print-hidden {
  @media print {
    display: none;
  }
}

.PrevNextArrow {
  position: absolute;
  top: 25em;
  left: 5em;
  background: url('../../public/pictures/strzalka_02_left.png');
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  width: 80px;
  height: 80px;
}

.PrevNextArrow:hover {
  background: url('../../public/pictures/strzalka_02_left_orange.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.PrevNextArrow.next-arrow {
  right: 5em;
  left: auto;
  background: url('../../public/pictures/strzalka_02.png');
  background-size: 100%;
  background-repeat: no-repeat;
}

.PrevNextArrow.next-arrow:hover {
  background: url('../../public/pictures/strzalka_02_orange.png');
  background-repeat: no-repeat;
  background-size: 100%;
}
