@font-face {
  font-family: 'Roboto';
  src: url('../../public/roboto-bold-webfont.woff2') format('woff2'),
    url('../../public/roboto-bold-webfont.woff') format('woff'),
    url('../../public/Roboto-Bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../public/roboto-light-webfont.woff2') format('woff2'),
    url('../../public/roboto-light-webfont.woff') format('woff'),
    url('../../public/Roboto-Light.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../public/roboto-medium-webfont.woff2') format('woff2'),
    url('../../public/roboto-medium-webfont.woff') format('woff'),
    url('../../public/Roboto-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../public/roboto-regular-webfont.woff2') format('woff2'),
    url('../../public/roboto-regular-webfont.woff') format('woff'),
    url('../../public/Roboto-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}
