.ant-spin {
  color: #ef7d06 !important;
  font-size: 28px;
}

.ant-spin-dot {
  width: 2em;
  height: 2em;
}

.ant-spin-lg .ant-spin-dot i {
  width: 28px;
  height: 28px;
}

.ant-spin-dot-item {
  background-color: #ef7d06;
}
