body {
  font-family: Helvetica, "Open Sans", Arial, sans-serif !important;
}


.login-container {
  width: 270px;
  display: flex;
  flex-direction: column;

}

.login-container .login-inputs {
  width: 270px;
  margin: 5px 0;
}

.login-container .login-button {
  margin-top: 50px;
}

.logo {
  margin-top: 200px;
  height: 200px;
  /*background: url("../../media/logo.png")  no-repeat center;*/
}

.h2-login-title, .h2-login-info {
  font-weight: 700;
}

.h2-login-title {
  color: #ef7d06;
}

.login-container {

  justify-content: left;
}

.login-main-container {
  margin-top: 10%;
  height: 100%;
}

.btn-footer-login {
  text-align: end;
}

#new-mission-name, #confirm-new-mission-name {
  color: #ef7d06;
}

.MuiInput-input {
  color: #ef7d06 !important;
}
