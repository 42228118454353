.card {
    width: 100%;
}

.card .action-item {
    margin: 0 40px 0 5px;
    color: #575756;
    text-transform: uppercase;
    font-size: 13px;

}

.card .action-item>a {
    color: #bcbcbc;
}

.card .action-item>a:hover {
    cursor: pointer;
    color: #ef7d06;
}

.card .action-item:hover {
    cursor: pointer;
    color: #ef7d06;
}

.card .action-container {
    margin: 10px 0 10px 0;
}

.card .body-container {
    border: 1px solid #bcbcbc;
    padding: 5px 10px;
}

.confirmation-text {
    color: #ef7d06;
    margin-left: 5px;
    font-weight: 700;
}

.yes-no-actions {
    font-weight: 700;
    color: #bcbcbc;
}

.yes-no-actions:hover {
    color: #ef7d06;
    cursor: pointer;
}

.card-can-clicked:hover {
    border: 1px solid #ef7d06;
    cursor: pointer;
}

.card-header {
    display: inline-block;
}

.card-header:hover .card-title {
    color: #ef7d06;
    border-color: #ef7d06;
    cursor: pointer;
}

.card-header:hover {
    border-color: #ef7d06;
    cursor: pointer;
}

.card-header:hover~.card-missions {
    border-color: #ef7d06;
}
