.logoContainer {
  width: 130px;
  height: 75px;
  text-align: center;
  display: flex;
  flex-direction: row;
  background: url('../../../../public/pictures/logo.png');
  background-size: cover;
  background-repeat: no-repeat;
}
