.wrapper {
  width: 400px;
  padding: 16px;
  [data-rmiz-modal] {
    overflow: hidden;
    &[open] {
      margin: auto;
    }
  }
}

.img {
  max-width: 368px;
}
