.helpBtn {
  color: #fff !important;
  &:hover {
    color: #ff8c00 !important;
    transition: all 0.5s;
    svg {
      color: #ff8c00 !important;
      transition: all 0.5s;
    }
  }
}

.btnIcon {
  color: #fff !important;
}
