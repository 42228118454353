.buttonWrapper {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 10px;
}

.facetWrapper {
  padding-right: 10px;
  ul {
    list-style: none;
    padding-left: 0;
  }
}

.facetWrapperBlock {
  padding-right: 10px;
  max-width: 24%;
  width: 100%;
  ul {
    height: 250px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: black;
    }
    li {
      margin-right: 10px;
    }
  }
  &:last-child {
    margin-right: auto;
  }
  @media screen and (max-width: 760px) {
    max-width: unset;
  }
}

.facetGroupName {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 5px;
  margin-top: 10px;
  border-bottom: solid 2px black;
  span {
    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.facet {
  display: block;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.facetLabel {
  width: 100%;
  padding-left: 10px;
}

.facetCount {
  padding-left: 10px;
}

.formHorizontal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 760px) {
    display: block;
  }
}

.btn {
  color: #fff !important;
  &:hover {
    color: #ff8c00 !important;
    transition: all 0.5s;
    svg {
      color: #ff8c00 !important;
      transition: all 0.5s;
    }
  }
}
